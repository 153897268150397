.serp-search-form {
    position: relative;

    form {
        width: 100%;
        height: 42px;
        /* overflow: auto; */
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        padding: 0 16px;

        input {
            @include card-shadows();
            border: 0px none;
            height: 42px;
            padding: 10px 42px 10px 10px;
            width: 100%;

            &:focus {
                outline: none;
            }
        }

        button {
            background: none;
            border: 0px none;
            color: $ldk-secondary-link-color;
            font-size: 18px;
            height: 42px;
            right: 16px;
            position: absolute;
            top: 0;
            width: 42px;

            &:hover {
                background: $ldk-link-hover-color;
            }
        }


        ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: $ldk-secondary-link-color;
            font-style: italic;
        }

        ::-moz-placeholder { /* Firefox 19+ */
            color: $ldk-secondary-link-color;
            opacity: 1;
            font-style: italic;
        }

        :-ms-input-placeholder { /* IE 10+ */
            color: $ldk-secondary-link-color;
            font-style: italic;
        }

        :-moz-placeholder { /* Firefox 18- */
            color: $ldk-secondary-link-color;
            opacity: 1;
            font-style: italic;
        }
    }
}

.search-result-page{
	color:$c-black;
	padding-left:0;
	padding-right:0;
	word-wrap:break-word;

	h1{
		font-size:30px;
	}

	// SERP SEARCH FORM
	.serp-search-form{
		background:url(../Images/search-form-bg.png) no-repeat left center;
		height: 74px;
		margin-bottom: 25px;
	}

	// SERP TABS
	.nav-tabs{
		margin-bottom:20px;

		.nav-item{
			+.nav-item{
				margin-left:0px;
			}
		}

		.nav-link{		
			background:#f1f1f1;
			border-bottom:1px solid #ddd;
			border-radius:0px;
			font-size:13px;
			line-height:16px;
			padding:7px 10px;
			text-align: center;
			text-transform: uppercase;

			&:hover{
				border-color:#ddd;
			}

			&.active{
				background:none;
				border-bottom:1px solid $c-white;
				color:$c-black;
				font-family:$ldk-heavy-font;
			}

			span{
				clear:both;
				display: block;
				font-size: 11px; 
			}
		}
	}

	// SERP ITEM
	.serp-item{
		@include clearfix();
		margin-bottom:25px;
		position: relative;	

		a{
			display:block;
			text-decoration: none;

			&:hover{	
				h2{
					text-decoration: underline;
				}
			}
		}
	}

	a{
		text-decoration: none;
	}

	h2{
		color:$c-black;
		font-family:$ldk-heavy-font;
		font-size:18px;
		margin-bottom:3px;
		padding-right:110px;
		text-transform: uppercase;
	}

	

	.serp-date{
		background:#b5c9d0;
		// border-right:30px solid $ldk-cat-5-color;
		color:$c-white;
		font-size:12px;
		line-height:14px;
		// padding:3px 10px;
		padding:3px 35px 2px 10px;
		position: absolute;
		right:0px;
		top:-1px;

		&.serp-date-locked{
			// border-right:29px solid #4E808D;
			background-image:url(../images/icon-search-locked.png);
			background-position:right center;
			background-repeat:no-repeat;

			i{
				font-size:16px;
				position: absolute;
				right:-20px;
				top:2px;
			}

			.fa-key{
				right:-23px;
			}
		}
	}

	.serp-breadcrumb{
		color:#a8a8a8;
		display: none;
		font-size:14px;
		line-height:16px;
		margin-bottom:4px;
	}

	p{
		font-size:16px;
		line-height:18px;
		margin-bottom:0;
	}

	// SERP FILTERS
	.serp-filters{
		@include clearfix();
		font-size:16px;
		margin-bottom:25px;
		width:100%;

		label,
		select{
			float:left;
		}

		label{
			margin-bottom:0;
			margin-right:5px;
		}

		select{			
			margin-bottom:5px;
			max-width:100%;
			width:230px;
		}

		.lbl-second{
			clear:both;
		}


	}

	// SERP SUMMARY
	.serp-summary{
		border-top:1px solid $c-border;
		font-family:$ldk-heavy-font;
		font-size:18px;
		margin-bottom:10px;
		padding-top:10px;
	}

}

@media (min-width: $screen-sm-min){
	.search-result-page{
		.serp-breadcrumb{
			display:block;
		}

		.serp-item{
			a{
				padding:5px;

				&:hover{
					background: #e1e1e1;
				}
			}
		}
		.serp-date{
			right:5px;
			top:4px;
		}

		.nav-tabs{
			.nav-link{
				font-size:19px;
				line-height:22px;
				padding:8px 19px 6px;

				span{
					clear:none;
					display: inline;
					font-size:12px;
				}
			}
		}
	}
}

@media (min-width: $screen-md-min){
	.search-result-page{
		.serp-filters{
			.lbl-second{
				clear:none;
			}

			select{
				max-width:200px;
			}

			label{
				margin-left:10px;

				&:first-child{
					margin-left:0;
				}
			}
		}
	}	
}

@media (min-width: $screen-lg-min){
	// .search-result-page{
	// 	padding-left:16px;
	// 	padding-right:16px;
	// }	
}

@media (min-width: $screen-xl-min){
	.search-result-page{
		.serp-filters{
			select{			
				max-width:250px;
			}
		}
	}	
}