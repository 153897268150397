.outer-box{
	padding:0px;
}

.box{
	@include clearfix();
	border:1px solid #e1e1e1;
	margin-bottom:30px;
	padding:6px;
	@include card-shadows();

	.box-left{
		float:left;
		padding-right:10px;
		width:60%;
	}

	.box-right{
		float:right;
		width:40%;
	}

	h3{
		color:$c-black;
		font-family:$ldk-heavy-font;
		font-size:18px;
		text-transform: uppercase;
	}

	p{
		font-family:$ldk-font;
		font-size:14px;
	}

	a{
		color: $ldk-link-color;

		&:hover{
			text-decoration: underline;
		}
	}

	hr{
		margin:5px 0;
	}

	.btn{
		color:$c-white;
		font-size:14px;
		margin-top:10px;
		white-space:normal;

		&:hover{
			text-decoration: none;
		}
	}
}

@media (min-width: $screen-md-min){
	.outer-box{
		&:nth-child(even){
			padding-left:15px;
		}
		&:nth-child(odd){
			clear:left;
			padding-right:15px;
		}
	}
}

@media (min-width: $screen-lg-min){
	.outer-box{
		padding:0 !important;
	}
}

