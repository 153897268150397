.tabbed-card-1 {
    .card-header {
        background: $ldk-card-header-color-1;
        border-bottom: 0px none;
        padding-bottom: 0;

    }
}

.tabbed-card-1 {
    .card-header {
        .card-title, .card-subtitle {
            color: $ldk-card-header-text-color-1;
        }
    }
}

.tabbed-card-2 {
    .card-header {
        background: $ldk-card-header-color-2;
        border-bottom: 0px none;
        padding-bottom: 0;
    }
}

.tabbed-card-2 {
    .card-header {
        .card-title, .card-subtitle {
            color: $ldk-card-header-text-color-2;
        }
    }
}



.tabbed-card-3 {
    .card-header {
        background: $ldk-card-header-color-3;
        border-bottom:0px none;
		padding-bottom:0;
    }
}

.tabbed-card-3 {
    .card-header {
        .card-title, .card-subtitle {
            color: $ldk-card-header-text-color-3;
        }
    }
}

.tabbed-card-4 {
    .card-header {
        background: $ldk-card-header-color-4;
        border-bottom:0px none;
		padding-bottom:0;
    }
}


.tabbed-card-4 {
    .card-header {
        .card-title, .card-subtitle {
            color: $ldk-card-header-text-color-4;
        }
    }
}

//Hack for Himmerland header color
div[id*='Himmerland'][data-portalservice-type='TabbedContent'].card {
    .card-header {
        background: #1a3c34;
    }
}

//Hack for DanePork header color
div[id*='DanePork'][data-portalservice-type='TabbedContent'].card {
    .card-header {
        background: #c6272b;
    }
}