.d-none {
	display: none;
}

@each $name, $break in $grid-breakpoints {
	$min: breakpoint-min($name);
	@media (min-width: $min) {
		.d-#{$name}-none {
			display: none;
		}
	}
}

.d-block {
	display: block;
}

@each $name, $break in $grid-breakpoints {
	$min: breakpoint-min($name);
	@media (min-width: $min) {
		.d-#{$name}-block {
			display: block;
		}
	}
}