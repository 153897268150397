// NOTIFICATIONS
.notifications{
	float:right;	
	margin-top:10px;
	position: relative;

	&:hover,
	&:focus{
		text-decoration:none;
	}

	.notification-count{
		background: $ldk-button-color;
		border-radius:5em;
		color:$c-white;
		display:block;
		font-size: 12px;
		height:20px;
		position:absolute;
		right:-5px;
		text-align:center;
		top:-5px;
		width:20px;
	}
}

.popover.notification-popover{
	display:none;
	@include card-shadows();
	border:1px solid rgba(0,0,0,.1);
	border-radius:0;
	// font-family:$ldk-bold-font;
	font-family:$ldk-font;
	// font-size:14px;
	font-size:16px;
	left:0;
	line-height:18px;
	max-width:100%;
	right:75px;
	top:34px;
	width:100%;

	.popover-arrow{
		left:auto;
		right:86px;
	}	

	.popover-icon{
		margin-top:3px;
	}
}

.popover{
	ul {
		margin-bottom:8px;
		padding:0;
	}

	li{
		border-bottom: 1px solid #dedede;
		list-style: none;

		a{
			display:block;
			padding:10px 0;

			@include clearfix();
		}

        a:hover{
        	background:$ldk-link-hover-color;
			text-decoration: none;
        }


		p{			
			margin:0;
		}

		&:last-child{
			border-bottom:0px none;
			a{
				padding-bottom:0;
			}
		}
	}

	.alarm{
		.fa{
			// float:left;
			font-size:22px;
		}		
	}
	.alarm-high{
		.fa{
			color:$ldk-link-color;
		}
	}
	.alarm-medium{
		.fa{
			color:#ffde00;
		}
	}
	.alarm-normal{
		.fa{
			color:#09562c;
		}
	}

	.alarm-medium,
	.alarm-normal,
	.alarm-low{
		.popover-icon{
			display:none;
		}
	}

	.popover-header{
		color:$ldk-secondary-link-color;
		display:block;
		font-family: $ldk-heavy-font;
		font-size:14px;
		// margin-bottom: 10px;
		text-transform: uppercase;
	}

	.popover-icon{
		float:left;		
		text-align: center;
		width:32px;

		img{
			margin-top:4px;
		}
	}

	.alarm-high{
		.popover-inner-content{
			padding-left:40px;
		}
	}

	.lnk-load-more-notifications{
		border-top:1px solid #dedede;
		display: block;
		font-family:$ldk-bold-font;
		padding:4px;
		text-align: center;
		width:100%;
	}
}

@media (min-width: $screen-sm-min){
	.notifications{
		margin-left:20px;
		margin-top: 16px;
	}
	.popover.notification-popover{
		left:auto;		
		top:46px;
		width:255px;

		.popover-arrow{
			right:15px;
		}
	}

}

@media (min-width: $screen-md-min){
	.notifications{
		// margin-top: 13px;
		margin-left:10px;

		.notification-count{
			font-size:14px;
			height:22px;
			width:22px;
		}
	}

	.popover.notification-popover{
		right:82px;
		top:50px;
	}
}

@media (min-width: $screen-lg-min){}

@media (min-width: $screen-xl-min){}

//Larger screens than 1500px wide
@media (min-width: $screen-1500-min){
	.popover.notification-popover{
		//right:254px; // Use if the name is visible
		right:88px;
	}
}
