.pagination{
	margin-top:0;

	.page-item{
		.page-link{
			border-radius:0px !important;

			&:hover{
				color:$ldk-pagination-color;
			}
		}

		&.active{
			.page-link,
			.page-link:focus,
			.page-link:hover{
				color:$c-white;
			    background-color: $ldk-pagination-color;
			    border-color: $ldk-pagination-color;
			}
		}
	}
}


