.gyllelog-card{
	table{
		a{
			color:$c-black;
		}
	}
}

.card-block {
	table{
		font-size:14px;
		font-weight:700;
		margin-bottom:10px;

		th{
			color: #757575;
			font-weight:700;
			padding-bottom:7px;
			padding-right:25px;

			&:last-child{
				padding-right:0;
			}
		}

		td{
			padding-bottom:4px;
			padding-right:30px;

			&:last-child{
				padding-right:0;
			}
		}

		tr{
			&:last-child{
				td{
					padding-bottom:0;
				}
			}
		}

		.fa{
			font-size:22px;
		}

		.ok{
			.fa{
				color: $ldk-card-header-color-1;
			}
		}

		.warning{
			.fa{
				color:$ldk-link-color;
			}
		}
	}
}

@media (min-width: $screen-md-min){
	.gyllelog-card{
		// height:330px;
		
		table{
			margin-bottom:0;
		}
	}
}