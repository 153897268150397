.podcast-card{
	.card-header{
		background:$ldk-card-header-color-1;
		border-bottom:0px none;
		padding-bottom:0;
		
		.card-title,
		.card-subtitle{
			color:$c-white;
		}
	}

	.card-block{
		padding: 0;
	}

	.list-group-item{
		border:0px none;
		color:$c-black;
		font-family:$ldk-font;
		//margin-bottom:8px;
		margin-bottom:0;

		&:last-child{
			margin:0;
		}

		a{
			//padding:0;
			padding: 9px 16px;

			&:hover,
			&.active{
				background:$ldk-link-hover-color;
				//color:$ldk-link-color;
			}
		}
	}

	.list-item-right{
		float:right;
		font-size:15px;
		line-height:22px;
		margin-left:12px;
	}

	.list-item-left{
		padding-right:50px;
	}

	h5{
		font-family: $ldk-heavy-font;
		font-size:18px;
		text-transform: uppercase;
	}

	.secondary-info{
		font-size: 15px;
		overflow: hidden;
	    display: block;
        line-height: normal;
	}
}

.audio-player{
	color:$c-white;
	display:none;
	font-family:$ldk-light-font;
	font-size:14px;
	// height:200px;
	margin:20px 0 30px 0;
	padding-top:50px;
	position:relative;
	text-align:center;

	.close-audio{
		color:$c-white;
		font-size:36px;
		line-height:20px;
		right:0;
		position: absolute;
		top:0;
		z-index:4000;

		&:hover{
			text-decoration:none;
		}
	}

	.audio-number,
	.audio-title{
		display:block;
		clear:both;
	}
	.audio-title{
		display:none;
	}
}

.mejs-container{
	position:static;
	width:100% !important;

	.mejs-controls{
		background:$ldk-card-header-color-1;
		margin-top:5px;
		// position:relative;
		position:static;
		div{
			font-family:$ldk-light-font;
			font-size:12px;
		}

		.mejs-playpause-button{
			position:absolute;
			text-align:center;
			top:0;
			width:100%;

			button{
				background:none;
				color:$c-white;
				display:inline-block;
				font:normal normal normal 32px/1 FontAwesome;
				margin:0;
				height:36px;
				position:relative;
				width:36px;
			}

			&.mejs-play{
				button{
					&:before{
						content: $fa-var-play;
					}						
				}
			}
			&.mejs-pause{
				button{
					&:before{
						content: $fa-var-pause;
					}						
				}
			}
		}

		.mejs-time-rail{
			padding-top:30px;
			width:100%;
		} 

		.mejs-time{
			padding:0;
			position:absolute;
			width:100%;
		}

		.mejs-time-handle{
			border:0px none;
			display:block;
			margin-top:-3px;
			height:12px;
			width:12px;
		}

		.mejs-time-loaded{
			display:none;
		}

		.mejs-time-total{
			background:$c-white;
			height:2px;
			margin:0;
			width:100% !important;

			&:focus{
				outline:none;
			}
		}

		.mejs-time-current{
			background:$c-white;
			height:4px;
			border-radius:0px;
			margin-top:-1px;
		}

		.mejs-time-float{
			color:$c-black;
			font-family:$ldk-font;
			z-index:2000;
		}
	}
}

@media (min-width: $screen-sm-min){
	.podcast-card{
		.list-item-right{
			font-size:16px;
		}

		.secondary-info{
			font-size:16px;
		}
	}
}

@media (min-width: $screen-md-min){
	.podcast-card{
		// height:495px;
		.list-group-item{
			height:50px;
		}

		h5{
			height:20px;
		}

		.secondary-info{
            white-space: nowrap;
		}
	}
}