.administration-page{
	.links-administration{
		padding:15px 0;
	}

	.list-group{
		font-family:$ldk-font;

		a{
			text-decoration: none;

			&:hover{
				text-decoration: underline;
			}
		}
	}

	.list-group-item{
		background:none;
		&:hover{
			background:#f9f9f9;
		}

		&:first-child{
			border-top:1px solid $c-border;
		}

		&:last-child{
			border-bottom:1px solid $c-border;
		}

		.primary-link{
			padding-left:30px;
			word-break:break-word;
		}
	}

	.right-links{
		float:right;
		text-align: right;
		width:25%;

		a{
			color:#777777;
			color:rgba($c-black,0.54);

			&:hover{
				color:$c-black;
			}
		}
	}

	.left-link{
		padding-right:5%;
		width:75%;

		&.no-right{
			padding-right:0;
			width:100%;
		}
	}

	.drag-icon,
	.add-elem-icon{
		color:#777777;
		color:rgba($c-black,0.54);
		position: absolute;

		&:hover{
			color:$c-black;
		}
	}

	.delete-elem{
		margin-left:15px;
	}

	.edit-elem-form{
		border-top:1px solid $c-border;
		clear:both;
		display:none;
		float:left;
		font-size:15px;
		margin-top:10px;
		padding-top:10px;
		text-align:right;
		width:100%;

		label{
			margin:0;
		}

		input{
			margin-bottom:10px;
			padding: 1px 3px;
		}

		&.open{
			display:block;
		}
	}	
}

.create-link-btn{
	.fa{
		margin-right:5px;
	}
}

.create-link-container{
	@include clearfix();
	padding:15px;
	text-align: right;

	.edit-elem-form{
		border-top:0px none;	
	}

	form{
		font-family: $ldk-font;
	}
}

.admin-sub-section{
	// background:$c-white;
	padding:20px 0 0 0;
	h3{
		font-family: $ldk-bold-font;
		font-size:20px;
		padding:0 15px;
	}
}