// USER SETTIGNS IN TOPBAR
.user-settings {
    float: right;
    margin-left: 15px;
    margin-right: 16px;
    margin-top: 11px;
    position: relative;

    &.user-settings-name {
        font-family: $ldk-light-font;
        font-size: 1.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .fa {
        position: relative;
        right: 0;
        top: -1px;
    }

    &:hover,
    &:focus {
        text-decoration: none;

        .user-settings-name {
            text-decoration: underline;
        }
    }
}

.act-as {
    p {
        font-family: $ldk-light-font;
    }

    h1, h2, h3, h4, h5, h6 {
        font-family: $ldk-heavy-font;
        color: $c-black;
    }

    button,
    a.button {
        background: $ldk-actas-button-background;
        color: $c-white;
        display: inline-block;
        font-family: $ldk-bold-font;
        font-size: 26px;
        line-height: 26px;
        margin-bottom: 50px;
        padding: 10px 40px;
        text-decoration: none;
        border: none;

        &:hover {
            color: $c-white;
            text-decoration: none;
        }
    }

    .act-as-users {
        div {
            border-bottom: 1px solid lightgrey;
            padding-left: 15px;
            padding-top: 6px;
            padding-bottom: 6px;

            &.selected {
                font-family: $ldk-bold-font;
                color: $ldk-link-color;
            }
        }

        div:first-child {
            border-bottom: 1px solid black;
        }
    }
}

.popover.settings-popover{
	display:none;
	@include card-shadows();
	border:1px solid rgba(0,0,0,.1);
	border-radius:0;
	font-family:$ldk-bold-font;
	font-size:14px;
	left:0;
	line-height:18px;
	max-width:100%;
	right:5px;
	text-transform: uppercase;
	top:34px;
	width:100%;

	.popover-arrow{
		left:auto;
		right:14px;
	}	

	ul{
		margin-bottom:0;
	}

	li{
		&:first-child{
			a{
				padding-top:0;
			}
		}
	}

	.popover-inner-content{
		padding-left:40px;
		padding-top:5px;
	}
}

@media (min-width: $screen-sm-min){
    .user-settings {
        margin-left: 15px;
        margin-top: 17px;
    }

	.popover.settings-popover{
		left:auto;		
		top:46px;
		width:200px;

		.popover-arrow{
			right:9px;
		}
	}
}

@media (min-width: $screen-md-min){
    .user-settings {
        margin-right: 0;
        // margin-top:14px;
        &.user-settings-name {
            width: 68px;
        }
    }

	.popover.settings-popover{
		// right:82px;
		top:50px;
	}
}

@media (min-width: $screen-lg-min){}

@media (min-width: $screen-xl-min){
    .user-settings {
        &.user-settings-name {
            width: 105px;
        }
    }
}

//Larger screens than 1500px wide
@media (min-width: $screen-1500-min) {
    .user-settings {
        padding-right: 30px;

        &.user-settings-name {
            width: 115px;
        }

        .fa {
            display: inline;
            position: absolute;
        }
    }
}

