.modal{
	z-index:4500;
}

.modal-backdrop{
	z-index:4400;
}

.modal-body{
	p:last-child{
		margin-bottom:0;
	}
}

.modal-footer{
	.card-footer{
		background:none;
		border:0px none;
		float:right;
		min-height:10px;
		padding:0px;
		position:relative;
		width:auto;
	}

	.modal-chk{
		float:left;
		margin-top:5px;

		label{
			margin-bottom:0;
		}
	}
}

a[data-modal] {
    cursor: pointer;
}

[data-modal-body] {
    display: none;
}