.btn-collection{
	margin-bottom:25px;
	text-align:right;	
}

.btn{
	border:0px none;
	border-radius:0px;
	color:$c-white;
	font-family: $ldk-bold-font;
	font-size:16px;
	text-transform: uppercase;

	&.btn-primary{
		background: $ldk-button-color;

		&:focus{
			background: $ldk-button-color;
		}

		&:hover{
			background:#bd4b0f;
		}
	}

	&.btn-secondary{
		background:$ldk-secondary-link-color;

		&:active,
		&:focus{
			background:$ldk-secondary-link-color;
			color:$c-white;
		}

		&:hover{
			background: #373738;
			color:$c-white;
		}
	}

	+.btn{
		margin-left:13px;
	}
}