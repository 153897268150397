// Primary colors
$c-white		: #fff !default;
$c-black		: #000 !default;

$c-grey			: #d8dad5 !default;
$c-yellow		: #d6ca40 !default;
$c-red			: #df2027 !default;

$c-border       : #e0e0e0 !default;

$ldk-background-color           : $c-grey !default;
$ldk-navbar-color               : inherit !default;
$ldk-navbar-bgcolor             : #f0f0e9 !default;
$ldk-navbar-link-color          : #58595b !default;
$ldk-navbar-active-color        : $c-black !default;
$ldk-navbar-hover-color         : #58595b !default;

$ldk-header-color               : $ldk-navbar-color !default;
$ldk-header-bgcolor             : $ldk-navbar-bgcolor !default;
$ldk-header-link-color          : $ldk-navbar-link-color !default;
$ldk-header-active-color        : $ldk-navbar-active-color !default;
$ldk-header-hover-color         : $ldk-navbar-hover-color !default;

$ldk-footer-color               : $ldk-navbar-color !default;
$ldk-footer-bgcolor             : $ldk-navbar-bgcolor !default;
$ldk-footer-link-color          : $ldk-navbar-link-color !default;
$ldk-footer-active-color        : $ldk-navbar-active-color !default;
$ldk-footer-hover-color         : $ldk-navbar-hover-color !default;

$ldk-card-title-color           : rgba(0,0,0,0.87) !default;
$ldk-card-subtitle-color        : rgba(0,0,0,0.87) !default;
$ldk-card-color                 : $c-black !default;

$ldk-link-color                 : #ed6823 !default;
$ldk-link-hover-color           : #e1e1e1 !default;
$ldk-secondary-link-color       : #58595b !default;
$ldk-pagination-color           : #076471 !default;

$ldk-button-color               : #ed6823 !default;

$ldk-cat-1-color                : $c-black !default;    //Home
$ldk-cat-2-color                : #758f6d !default;
$ldk-cat-3-color                : #d6ca40 !default;
$ldk-cat-4-color                : #09562c !default;
$ldk-cat-5-color                : #7da3ad !default;
$ldk-cat-6-color                : #076471 !default;
$ldk-cat-7-color                : #df2027 !default;   //Favorites

$ldk-actas-button-background    : $ldk-cat-5-color !default;

$ldk-card-alert-color           : #df2027 !default;
$ldk-card-warning-color         : #ed6823 !default;
$ldk-card-information-color     : #758f6d !default;
$ldk-card-neutral-color         : #000000 !default;

$ldk-card-header-color-1        : #09562c !default;
$ldk-card-header-color-2        : #076471 !default;
$ldk-card-header-color-3        : #7DA3AD !default;
$ldk-card-header-color-4        : #D6CA40 !default;

$ldk-card-header-text-color-1   : $c-white !default;
$ldk-card-header-text-color-2   : $c-white !default;
$ldk-card-header-text-color-3   : $c-black !default;
$ldk-card-header-text-color-4   : $c-black !default;

$ldk-service-icon-color         : $c-white !default;
$ldk-service-icon-bgcolor       : $c-black !default;