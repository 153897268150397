.navbar {
	color: $ldk-header-color;
	background: $ldk-header-bgcolor;
	-webkit-box-shadow: 0px 2px 4px rgba(0,0,0,0.26);
	-moz-box-shadow: 0px 2px 4px rgba(0,0,0,0.26);
	box-shadow: 0px 2px 4px rgba(0,0,0,0.26);
	border-radius: 0px;
	font-family: $ldk-bold-font;
	margin-bottom: 15px;
	padding: 0;

	.nav-link {
		font-size: $ldk-navbar-link-size;
		padding: 15px;

		.inner-span {
			display: block;

			span {
				vertical-align: middle;
			}
		}

		&:hover,
		&:focus {
			background-color: $ldk-background-color;
			color: $ldk-header-hover-color;
			// box-shadow: inset 7px 0 7px -7px rgba(0,0,0,0.35);
			// .inner-span{
			// 	box-shadow: inset -7px 0 7px -7px rgba(0,0,0,0.35);
			// }
		}

		.fa {
			font-size: $ldk-navbar-icon-size;
			vertical-align: middle;
			width: 50px;
		}
	}

	a {
		color: $ldk-header-link-color;
		position: relative;
		z-index: 4000;
	}

	.fa {
		font-size: 24px;
	}

	.cat-1 {
		border-left: 5px solid $ldk-cat-1-color;
	}

	.cat-2 {
		border-left: 5px solid $ldk-cat-2-color;
	}

	.cat-3 {
		border-left: 5px solid $ldk-cat-3-color;
	}

	.cat-4 {
		border-left: 5px solid $ldk-cat-4-color;
	}

	.cat-5 {
		border-left: 5px solid $ldk-cat-5-color;
	}

	.cat-6 {
		border-left: 5px solid $ldk-cat-6-color;
	}

	.cat-7 {
		border-left: 5px solid $ldk-cat-7-color;
	}

	.navbar-nav .nav-item {
		margin-left: 0;
		position: relative;
	}

	.active {
		a {
			color: $ldk-header-active-color;
		}
	}
}

// ADD ICON
.add-icon{
	float:right;
	margin-top:10px;
	margin-left:15px;

	&:hover,
	&:focus{
		text-decoration: none;
	}
}

#navbar-header{
	background:$c-grey;
	-webkit-box-shadow: 0px 2px 4px rgba(0,0,0,0.26);
	-moz-box-shadow: 0px 2px 4px rgba(0,0,0,0.26);
	box-shadow: 0px 2px 4px rgba(0,0,0,0.26);
	clear:both;
	position:absolute;
	top:45px;
	z-index:1000;
	width:100%;
}

// BURGER MENU ICON
.navbar-toggler{
	color:$ldk-secondary-link-color;
	float:right;
	margin-top:-1px;
	.fa{
		font-size:28px;	
	}

	span{
		display:none;
		font-family: $ldk-heavy-font;
		font-size:22px;
	}
}

@media (min-width: $screen-sm-min){
	.navbar-toggler{
		margin-top:4px;
		margin-right:30px;
		padding-right:70px;
		position: relative;

		span{
			display:inline;
			position: absolute;
			right: 0;
			top:11px;
		}
	}	
	#navbar-header{
		top:54px;
	}

	.add-icon{
		margin-top:17px;
		margin-left:20px;
	}

	.nav{
		@include clearfix();
	}
}

@media (min-width: $screen-md-min){
	// .active-shadow-outer{
	// 	background: $c-grey;
	// 	-webkit-box-shadow: inset -7px 0 7px -7px rgba(0,0,0,0.35);
	// 	-moz-box-shadow: inset -7px 0 7px -7px rgba(0,0,0,0.35);
	// 	box-shadow: inset -7px 0 7px -7px rgba(0,0,0,0.35);

	// 	display:block;
	// 	height:5px;
	// 	position:absolute;
	// 	bottom:-4px;
	// 	width:100%;

	// 	span{
	// 		-webkit-box-shadow: inset 7px 0 7px -7px rgba(0,0,0,0.35);
	// 		-moz-box-shadow: inset 7px 0 7px -7px rgba(0,0,0,0.35);
	// 		box-shadow: inset 7px 0 7px -7px rgba(0,0,0,0.35);

	// 		display:block;
	// 		height:5px;
	// 	}
	// }
	#navbar-header{
		background:none;
		box-shadow: none;
		clear:none;
		position:relative;
		top:auto;
	}	

	.navbar{
		height:65px;
		margin-bottom:30px;
		padding:0 15px;

		.nav-item{
			border-left:0px none;
		}

		.nav-link{
			padding: 0;
			text-align:center;
			font-size:60%;
			text-transform: uppercase;	

			.inner-span{
				padding-bottom:8px;
				padding-top:9px;	
			}

			.fa{
				width:auto;
			}

			&:hover,
			&:focus{
				.inner-span{
					//padding-bottom:10px;
					padding-bottom:13px;
				}
			}
		}

		.cat-1{
			border-top: 5px solid $ldk-cat-1-color;
		}
		.cat-2{
			border-top: 5px solid $ldk-cat-2-color;
		}
		.cat-3{
			border-top: 5px solid $ldk-cat-3-color;
		}
		.cat-4{
			border-top: 5px solid $ldk-cat-4-color;
		}
		.cat-5{
			border-top: 5px solid $ldk-cat-5-color;
		}
		.cat-6{
			border-top: 5px solid $ldk-cat-6-color;
		}
		.cat-7{
			border-top: 5px solid $ldk-cat-7-color;
		}

		.fa{
			clear:both;
			display:block;
			font-size:28px;
		}

		.active{
			a{
				background-color: $ldk-background-color;
				-webkit-box-shadow: inset 7px 0 7px -7px rgba(0,0,0,0.35);
				-moz-box-shadow: inset 7px 0 7px -7px rgba(0,0,0,0.35);
				box-shadow: inset 7px 0 7px -7px rgba(0,0,0,0.35);

				&:hover,
				&:focus{
					.inner-span{
						padding-bottom:6px;
					}
				}
			}
			.inner-span{
				-webkit-box-shadow: inset -7px 0 7px -7px rgba(0,0,0,0.35);
				-moz-box-shadow: inset -7px 0 7px -7px rgba(0,0,0,0.35);
				box-shadow: inset -7px 0 7px -7px rgba(0,0,0,0.35);
                border-bottom:5px solid $ldk-background-color;
			}
		}
	}	
	.navbar-nav{
		margin: 0 auto;
		width: 501px; // total width of li's (6x70) + difference in width (41) between logo(140px) and toolbar(109) in right corner	    
	    padding-left: 41px; // difference in width (41) between logo(140px) and toolbar(109) in right corner

		.nav-link{
			width:70px;
		}	

		&.show-less-items {
			width:431px;
		}
	}			 	
}

@media (min-width: $screen-lg-min){
	.navbar{
		.nav-link{
			font-size: 13px;

			.inner-span{
				padding-top:6px;	
			}
		}
	}

    .navbar-nav {
        padding-left: 0px;
        width: 540px;

        .nav-link {
            width: 90px;
        }

        &.show-less-items {
			width:451px;
		}
    }		
}

@media (min-width: $screen-xl-min){
    .navbar-nav {
        width: 690px;

        .nav-link {
            width: 115px;
        }

        &.show-less-items {
			width:575px;
		}
    }	
}
//Larger screens than 1400px wide
@media (min-width: 1400px){
    .navbar-nav {
        width: 840px;
        padding-left: 0;

        .nav-link {
        	width:140px;
        }

        &.show-less-items {
			width:700px;
		}
    }
}

//Larger screens than 1500px wide
@media (min-width: $screen-1500-min){
    .navbar-nav {
        width: 901px;
        .nav-link {
            width: 150px;
        }

        &.show-less-items {
			width:750px;
		}
    }	
}



