﻿.traffic-light {
    .traffic-light-current {
        border-radius: 50%;
        width: 120px;
        height: 120px;
        margin: 0 auto;
    }
    .traffic-light-option {
        border-radius: 50%;
        width: 12px;
        height: 12px;
        display: inline-block;
    } 
}