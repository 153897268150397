.plant-it-card {
    .nav-tabs a {
        // Juuust make "Administration" fit by shaving off 8 pixels of padding
        padding-left: 4px;
        padding-right: 4px;

        &:hover {
            background: none;
        }
    }

    .list-group-item {
        border: 0px none;
        color: $c-black;
        font-family: $ldk-font;
        //margin-bottom: 8px;
        margin-bottom: 0;

        &:last-child {
            margin: 0;
        }

        a {
            // padding: 0;
            padding: 8px 16px;
            display: block;

            &:hover,
            &.active {
                // background: none;
                // color: $ldk-link-color;
                text-decoration: none;
                background:$ldk-link-hover-color;
            }
        }
    }

    h5 {
        font-family: $ldk-heavy-font;
        font-size: 18px;
        text-transform: uppercase;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            //margin-bottom: 12px;
            margin-bottom: 0;

            &:last-child {
                margin-bottom: 0;
            }

            &.no-image {
                padding-left: 65px;
            }
        }

        img {
            float: left;
            margin-right: 25px;
            height: 40px;
            width: 40px;
        }

        span {
            .programlink {
                text-transform: uppercase;
            }
        }

        a {
            color: $c-black;
            //display: block;
            // font-family:$ldk-primary-font;
            // font-size:20px;
            // line-height: 20px;
            padding: 8px 16px;
            display: block;
            @include clearfix();

            &:hover {
                text-decoration: none;
                background:$ldk-link-hover-color;
            }
        }


        .outer-span {
            display: table-cell;
            height: 40px;
            vertical-align: middle;
            font-family: $ldk-bold-font;
            font-size: 20px;
            line-height: 20px;
        }

        .inactive {
            opacity: 0.2;
            padding: 8px 16px;
        }
    }

    .card-header {
        background: $ldk-card-header-color-1;
        border-bottom: 0px none;
        padding-bottom: 0;

        .card-title,
        .card-subtitle {
            color: $c-white;
        }
    }

    .card-block{
        padding: 0;
    }

}