.course-card{
	.card-header{
		background:$ldk-card-header-color-1;
		border-bottom:0px none;
		padding-bottom:0;
		
		.card-title,
		.card-subtitle{
			color:$c-white;
		}

        .nav-tabs{
            font-size:16px;   
        }
	}

}

