.service-not-available {
    color: $ldk-card-title-color;
    font-family: $ldk-font;
    // margin-top:15px;
    text-align: center;

    .fa {
        clear: both;
        color: $ldk-cat-5-color;
        display: block;
        // font-size: 90px;
        font-size: 60px;
        margin-top: 20px;
    }
}
