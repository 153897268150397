.rss-card{
	.list-item-right{
		float:right;
		margin-left:24px;
	}	
}

.list-group-item{
	border-radius:0px;
	border:0px none;
	border-bottom:1px solid $c-border;
	
	margin-bottom:0px;
	padding:0;

	&:last-child{
		border-bottom:0px none;
		border-radius:0px;
	}

	a{
		color: $ldk-card-color;	
		@include clearfix();
		display:block;
		padding:14px 16px;

		&:hover{
			background:$ldk-link-hover-color;
			
		}

		&:hover,
		&:focus{
			text-decoration:none;
			img{
				opacity:1;
			}
		}

		img{
			height:75px;
			width:100px;
		}

	}

	h5{		
		font-family: $ldk-font;
		font-size:15px;
		margin-bottom:1px;			
		overflow:hidden;
		display: block; /* Fallback for non-webkit */
		// display: -webkit-box;
		// -webkit-line-clamp: 3;
		// -webkit-box-orient: vertical;
		// overflow: hidden;
		// text-overflow: ellipsis;
		
	}

	.secondary-info{
		font-size:12px;
		line-height:12px;
	}
}


@media (min-width: $screen-md-min){
	.rss-card{
		// height: 645px;

		.list-group-item{
			a{height:104px;}

			h5{
				max-height:48px;
			}
		}
	}	

	.rss-card-small{
		// height:550px;

		.list-group-item{
			a{
				height:85px;
			}

			h5{
				max-height:33px;
			}
		}
	}

	.rss-card-mini{
		// height:347px;
	}

	.rss-card-mini-image{
		// height:540px;
		// height:518px;
	}
}