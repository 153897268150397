.edit-services-container{
	color:$c-black;
	padding-top:25px;
	position: relative;

	h1{
		font-size:30px;
		padding-right:40px;
	}

	h3{
		color:$c-black;
		word-wrap:break-word;
	}

	.lnk-cancel{
		font-size:28px;
		right:20px;
		position: absolute;
		top:14px;
	}

	.services-item{
		background: #efefef;
		margin-bottom:25px;
	}

	.services-header{		
		font-family: $ldk-bold-font;
		font-size:25px;
		height:47px;
		margin-bottom:0;
		text-transform:uppercase;

		a{
			background: $c-grey;
			display:block;
			height:100%;
			text-decoration: none;
			@include clearfix();

			&:hover{
				background: $c-grey;
			}
		}
	}

	.service-icon{
		background:$ldk-service-icon-bgcolor;
		color:$ldk-service-icon-color;
		display: inline-block;
		float:left;
		height:47px;
		text-align: center;
		width:47px;

		.fa{
			font-size:29px;
			margin-top: 9px;
		}

		&.cat-2{
			background: $ldk-cat-2-color;
		}
		&.cat-3{
			background: $ldk-cat-3-color;
		}
		&.cat-4{
			background: $ldk-cat-4-color;
		}
		&.cat-5{
			background: $ldk-cat-5-color;
		}
		&.cat-6{
			background: $ldk-cat-6-color;
		}

	}

	.service-name{
		float:left;
		margin-left:22px;
		margin-top:10px;		
	}

	@media (max-width: $screen-sm-min){
		.service-name{
			font-size: 16px;
			margin-left: 10px;
			margin-top: 16px;
		}
	}
}

.open-close-icons{
	float:right;
	font-size:18px;
	margin: 16px 20px 0 0;
	
	.fa-plus{
		display:block;
	}
	.fa-minus{
		display:none;
	}
}

.services-collection{
	@include clearfix();
	display:none;
    padding: 15px 0px;
}

.single-service{
	background:$c-white;
	border:3px solid $ldk-pagination-color;	
	border-radius:3px;
	cursor: pointer;
	font-size:12px;
	line-height:18px;
	padding:7px 12px 7px 7px;
	position: relative;
	width:100%;
	@include clearfix();
	@include card-shadows();
	transition: all 0.3s cubic-bezier(.25,.8,.25,1);

	&.active{		
		border:3px solid #898989;

		.single-service-content,
		.single-service-image{
			opacity:0.7;
		}
	}

	h3{
		font-family:$ldk-heavy-font;
		font-size:$ldk-optionalservice-title-font-size;
		margin-bottom:11px;
		text-transform: uppercase;

		@media (max-width: $screen-sm-min){
			font-size:12px;
		}
	}

	.edit-lnk{
		color:$ldk-button-color;
        bottom: 10px;
		font-family: $ldk-bold-font;
		right: 20px;
		position: absolute;
		text-decoration: none;
		text-transform: uppercase;

		&:hover{
		         text-decoration: underline;
		}

	}

	&:hover{
		box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
	}
}

.single-service-image{
	float:left;
	// height:200px;
	margin-right:7px;
	max-width:40%;

	img{
		height:auto;
	}
}

.single-service-content{
	height:100%;
	overflow: hidden;
	padding-bottom:15px;
}



.service-wrapper{
	padding:15px;
	width:100%;
}

.open{
	.services-collection{
		display:block;
	}

	.open-close-icons{
		.fa-plus{
			display:none;
		}
		.fa-minus{
			display:block;
		}
	}
}

#default-page {
    .page-row {
	    font-family:$ldk-bold-font;
        text-transform: uppercase;
        > div {
            cursor: pointer;
            padding: 12px 0px;
		    border-bottom: 1px solid #dedede;
            a {
                text-decoration: none;
            }
            .fa {
                //padding: 0px 24px 0px 12px;
                padding: 0px 12px 0px 12px;
            }
            .fa-check {
                float: right;
            }            
            &:hover {
                background-color: $c-grey;
            }
        }
    }    
}

@media (min-width: $screen-sm-min){
	.single-service-image{
		height:200px;
		max-width:180px;

		img{
			height:200px;
		}
	}

	.single-service-content{
		max-height:175px;
	}
}

@media (min-width: $screen-lg-min){
	.service-wrapper{
		float:left;
		width:420px;
	}

	.single-service{
		height:220px;
	}
}