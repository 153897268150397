body {
	background: $ldk-background-color;
	color:#555;
	font-family: $ldk-font;
}

h1,
h2,
h3,
h4,
.card-header{
	color:#404040;
	font-family:$ldk-font;
}

h1{
	font-size:36px;
}

img{
	max-width:100%;
}

a,
a:hover{
	color: $ldk-link-color;
}

a:focus{
	outline: none;
}

@media (min-width: $screen-md-min){
	h1{
		font-size:40px;
	}
}

@media (min-width: 1400px){
	.container{
		max-width:1350px;
	}

	.frontpage{
		.container{
			max-width:1140px;
		}
	}
}

/* 
    Correctly colored checkboxes. Used by DlbrOnlineBestilling (and potentially other places)
    Would be easier to override $custom-control-checked-indicator-bg and $custom-control-active-indicator-bg, but as we need to pick up the 
    colors per tenant, and the variables must be overridden before importing bootstraps SASS, that is not currently possible.
*/
.custom-control-input {
    &:checked ~ .custom-control-indicator {
        background-color: $ldk-button-color !important;
        box-shadow: none;
    }

    &:focus ~ .custom-control-indicator {
        box-shadow: none;
    }

    &:active ~ .custom-control-indicator {
        background-color: $ldk-button-color !important;
        opacity: 0.65;
        box-shadow: none;
    }
}