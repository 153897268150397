﻿.weekplanarea {
    border-style: solid;
    border-width: 3px;
    border-color: $c-border;
    text-align: center;
    padding: 4px;

    .card {
        border: 0px none !important;
        margin-bottom: 2px;
    }

    .weekplancolumn {
        text-align: center;
        padding: 0px 5px 0px 5px;

        .card {
            box-shadow: none;
            border: 0px none !important;
        }
    }

    .weekplanevent {
        padding: 2px;

        a {
            text-decoration: none;
        }

        .card {
            box-shadow: 0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12);
            background: #efefef;
            margin-bottom: 0px;
            padding: 5px 2px 0px 2px;

            .card-body {
                background: #985456 !important;
                margin: 10px 5px 10px 5px;
            }
        }
    }

    .weekplaneventbackground {
        background: $ldk-navbar-bgcolor !important;
    }

    .weekplandaybackground {
        background: $c-grey;
    }

    .weekplandaytodaybackground {
        background: $ldk-card-information-color;
    }

    .weekplanalternatecolor {
        color: $c-white;
    }

    .weekplanleft {
        text-align: left !important;
    }

    .weekplaneventdescription {
        text-wrap: normal;
        word-wrap: break-word;
        padding: 5px;
    }
}
.weekplancenter {
    vertical-align: middle;
    line-height: 60px;
    min-height: 60px;
}
.weekplanselectorarrow {
    margin: 0px 10px;
}
.weekplanbigtitle {
    font-family: $ldk-heavy-font;
    color: $ldk-card-title-color;
}


@media (min-width: $screen-sm-min) {
    .weekplanselector {
        width: 100%;
    }

    .weekplancolumn {
        width: 100%;
        margin-right: 1px;
    }
}

@media (min-width: $screen-md-min) {
    .weekplanevent {
        .card{
            margin-right: 2px !important;
        }
    }
    .weekplanselector {
        min-width: 300px;
    }

    .weekplancolumn {
        float: left;
        width: 14%;
        margin: 0px;
    }
}
