.textpage-content{
	background:$c-white;
	border-radius:2px;
	color:$c-black;
	font-family:$ldk-font;
	font-size:18px;
	line-height:22px;
	margin-bottom:15px;
	padding:30px 16px 24px;
	@include card-shadows();
	@include clearfix();

	h1{
		color:$c-black;
		margin-bottom:20px;
		text-transform:uppercase;

		.heavy-title{
			font-family:$ldk-heavy-font;
		}
	}

	a{
		color:$c-black;
		text-decoration: underline;

		&:hover,
		&:focus{
			text-decoration: none;
		}
	}
}

.error-page{
	text-align: center;

	img{
		border-radius:50em;
		margin-bottom:16px;
		@include card-shadows();
	}
}

@media (min-width: $screen-lg-min){
	.textpage-content{
		padding:30px 30px 34px;
	}

	.error-page{
		padding-top:50px;
	}
}