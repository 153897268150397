.links-card,
.links-administration{
	.list-group-item{
		padding:10px 16px;
		a{
			display:inline-block;
			padding:0;			

			&:hover{
				background:none;
				text-decoration: underline;
			}
		}
		@include clearfix();
	}

	.left-link{
		float:left;
		font-size:15px;

		.primary-link{
			font-family:$ldk-font;
			font-size:18px;
			vertical-align:middle;
            //word-break:break-all;
		}
	}

	@media (max-width: $screen-sm-min){
		.left-link{
			.primary-link{
				font-size: 15px;
			}
		}
	}
}

.links-card{
	.list-group-item{
		padding:0;

		a{
			padding:10px 16px;

			&:hover {
				background:$ldk-link-hover-color;
				text-decoration: none;
			}
		}
	}

	.left-link{
		width:100%;

		.primary-link{
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			width:100%;
		}
	}
}

.carousel-navigation{
 display:none;   
}

.links-administration .services-description
{
 display:none;   
}

@media (min-width: $screen-lg-min){
 .links-administration .services-description {
     display:block;
 }   
}
