﻿.consultant-card {

    pre.consultanttext {
        padding: 5px;
        white-space: pre-wrap;
        overflow-x: hidden;
        font-family: $ldk-font;
    }

    p {
        white-space: pre-wrap;
    }

    img.consultantimg {
        width: 120px;
    }

    img.consultant-logo {
        width: auto;
        height: auto;
        max-height: 50px;
    }

    .consultant-item {
        display: block;
    }

    .consultant-item-left {
        float: left;
        word-wrap: break-word;
    }

    .consultant-item-right {
        margin-left: -100px;
        float: right;
    }

    .consultant-item-contact {
        clear: both;
    }

    .consultant-logo-container {
        margin-top: 10px;
        margin-bottom: -15px;
    }
}


@media (min-width: $screen-md-min) {
    .consultant-card {
        .consultant-logo-container {
            margin-bottom: -35px;
        }
        .consultant-item-left {
            max-width: 150px;
        }
    }
}