.weather-card{
	.card-header{
		background:$ldk-card-header-color-2;
		border-bottom:0px none;
		padding-bottom:0;
		
		.card-title,
		.card-subtitle{
			color:$c-white;
		}
	}

	// .nav-tabs{
	// 	a{
	// 		color:$c-white;
	// 		text-decoration: none;
	// 	}

	// 	.nav-item+.nav-item{
	// 		margin-left:11px;
	// 	}
	// }
}

.weather-day{
	clear:both;
	font-family:$ldk-light-font;
	height:145px;
	position:relative;
	// @include clearfix();
	text-align:center;

	+.weather-day{
		margin-top:35px;
	}

	.weather-date{
		font-family:$ldk-font;
		font-size:16px;
	}

	.weather-degrees{
		clear:both;
		display:block;
		// font-size:76px;
		// line-height:90px;
		font-size:86px;
		line-height:100px;

		sup{
			top: -30px;
    		font-size: 50px;
		}
	}

	.weather-lg-icon{
		position: absolute;
		right:0;
		top:15px;
		max-width:115px;
	}

	.weather-footer{
		font-family:$ldk-font;
		clear:both;
		font-size:18px;
		width:100%;

		ol{
			font-size: 1.4rem;
			list-style-type: none;
			margin:0 auto;
			padding:0;
			width:180px;
		}

		li{
			float:left;
			width:85px;

			+li{
				margin-left: 10px;
			}

			&.wind-sign{
				width:65px;
			}
		}
	}

	.rain-chance{
		background: url(../Images/weather-card/rain-small.png) left 0 no-repeat;
		padding-left:30px;
	}
}

#tab2-w{
	.weather-day{
		clear:none;
		float:left;
		height:auto;
		padding-right:15px;
		margin-top:0;
		width:50%;
		@include clearfix();

		.weather-footer{
			ol{	font-size:18px;
				width:75px;
			}

			li{
				clear:both;
				margin-left:0;
				width:75px;
			}
		}

		+.weather-day{
			border-left:1px solid $c-border;
			padding-right:0;
			padding-left:15px;
			// text-align:right;

			.weather-footer{
				ol{
					// float:right;
				}
			}
		}

		.weather-degrees {
			font-size:66px;
			line-height:80px;
			sup{
				font-size:40px;
			}
		}
	}
}

.wind-icon{
	background: url(../Images/weather-card/wind-up.png) right 0 no-repeat;
	display: inline-block;
	height: 14px;
	margin-left:2px;
	width: 14px;
}

.forecast{
	font-family:$ldk-font;

	table{
		font-size:16px;
		margin-bottom:0;
		width:100%;

		td{
			font-weight:normal;
			padding-right:5px;
			// padding-bottom:17px;
			padding-bottom:18px;

			&:first-child{
				font-family:$ldk-heavy-font;
			}

			&:last-child{
				background: url(../Images/weather-card/rain-small.png) left 0 no-repeat;
    			padding-left: 32px;
			}
		}
	}
}

[data-precipitationinmm="0"] {
    background: transparent !important;
}

@media (min-width: $screen-md-min){
	.weather-card{
		// height:540px;
		 // height:536px;
		 // height:410px;
	}

	.weather-day{
		.weather-degrees{
			// font-size:87px;
		}

		.weather-lg-icon{
			max-width:125px;
			top:12px;
		}

		.weather-footer{
			// font-size:20px;
			ol{
				width:180px;
			}
			li{
				width:85px;

				+li{
					// margin-left:20px;
				}

				&.wind-sign{
					width:98px;
				}
			}
		}

		.wind-sign{
			background: url(../Images/weather-card/wind-icon.png) left 0 no-repeat;
			padding-left:32px;
		}
	}

	.forecast{
		table{
			// font-size:20px;

			td{
				padding-right:10px;
			}
		}
	}
}