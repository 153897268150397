// LOGO
.navbar-brand{
	padding:11px 0 11px 16px;
	margin-right:0;
	max-width:140px;

	img{
		max-width:100%;
        max-height: 44px;
	}
}

@media (min-width: $screen-sm-min){
	.navbar-brand{
		padding:11px 0 11px 16px;
		margin-right:25px;
		max-width:200px;
	}
}

@media (min-width: $screen-md-min){
	.navbar-brand{
		max-width:140px;
		padding-top:20px;
		padding-left:0;

		img{
			margin-left:10px;
		}
	}

	// .navbar-brand{
	// 	max-width:170px;
	// 	padding-top:18px;
	// }
}

@media (min-width: $screen-lg-min){
	.navbar-brand{
		max-width:175px;
		padding-top:17px;

		img{
			max-width:175px;
		}
	}
}

@media (min-width: $screen-xl-min){
	.navbar-brand{
		// margin-right:20px;
		max-width:195px;
		padding-top:15px;

		img {
			max-width:195px;
		}
	}
}

//Larger screens than 1500px wide
@media (min-width: $screen-1500-min){
	.navbar-brand{
		max-width:245px;
		padding-top:11px;

		img {
			max-width:245px;
		}
	}	
}