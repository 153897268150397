.mejeri-card{
	.card-block{
		background:$c-white;		
		text-align:center;
		padding:0;

		a{
			color:$c-black;
			display:block;
			padding:45px 16px;

			&:hover,
			&:focus{
				text-decoration: none;
			}
		}
	}
	.mejeri-title,
	.mejeri-subtitle{
		clear:both;
		display:block;
		width:100%;
	}

	.mejeri-title{
		font-family:$ldk-heavy-font;
		font-size:20px;
		text-transform: uppercase;

		&.secondary-title{
			font-size:18px;
		}
	}

	.mejeri-subtitle{
		font-family:$ldk-bold-font;
		font-size:17px;
	}
}

@media (min-width: $screen-md-min){
	.mejeri-card{
		.card-block{
			a{
				max-height:144px;
			}
		}
	}
}