.calendar-card{
	.list-item-date{
		font-family: $ldk-light-font;
		float:left;
		font-size: 46px;
	    line-height: 36px;
	    margin-right:30px;
	    text-align: center;
	    width: 50px;

		span{
			display:block;
		}

		.month{
			font-family:$ldk-font;
		    font-size: 12px;
		    line-height: 14px;
		    height:14px;
		    overflow:hidden;
		    text-align: center;
		}
	}
}

@media (min-width: $screen-md-min){
	.calendar-card{
		// height:623px;
		// height:594px;

		.list-group-item{
			a{
				// height:83px;
				height:78px;
			}

			.list-item-date{
				height:50px;
			}

			h5{
				max-height:50px;
			}
		}
	}	
}
