.topdanmarkslurry-card {
    .tab-pane {
        i.fa {
            padding-right: 5px;
        }

        [data-action] {
            cursor: pointer;
        }

        .top-dk-logo {
            text-align: center;
            img {
                max-width: 80%;
            }
        }

        > h5 {
            font-weight: bold;

            [data-action] {
                padding-right: 5px;
                cursor: pointer
            }
        }

        > [data-index] {
            border-bottom: 1px solid #E9E9E9;
            cursor: pointer;
            padding: 8px 0px;
            font-size: 16px;
            font-weight: bold;

            &.tank-alert {
                color: #E97132;
            }
        }

        &.tank {
            &[data-element] {
                display: none;
            }

            .tank-container {
                margin-top: 15px;
                margin-bottom: 15px;

                > .tank-wrapper {
                    position: relative;

                    > .tank-level {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        z-index: -1;
                        background-color: #8DC73F;

                        &.tank-alert {
                            background-color: #FF5C41;
                        }
                    }

                    > .tank-gauge {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        > div {
                            width: 50px;
                            height: 50px;
                            line-height: 50px;
                            text-align: center;
                            border-radius: 50%;
                            font-weight: bold;
                            background-color: rgba(220,220,220,0.6);
                        }
                    }

                    > img {
                        z-index: 1;
                    }
                }

                > .tank-footer {
                    font-size: 0;
                }
            }
        }
    }
}
