.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 60px;
    line-height: 60px;
    color: $ldk-footer-color;
    background: $ldk-footer-bgcolor;
    z-index: 4000;

    .form-control {
        display: inline-block;
    }

    a {
        text-transform: uppercase;
        text-decoration: none;
        color: $ldk-footer-link-color;
        font-family: $ldk-bold-font;

        &:hover {
            color: $ldk-footer-hover-color;
        }

        &:active {
            color: $ldk-footer-active-color;
        }
    }

    a > i.fa {
        padding-right: 5px;
    }
}
