.actionplans-card {
    a.btn:hover {
        background: $ldk-link-hover-color;
        text-decoration: none;
    }

    .btn-finish {
        color: white;
        background: #3F6613;
    }

    .btn-open {
        color: white;
        background: #3B7FC6;
    }

    .deadlinepassed {
        .fa,
        strong,
        p {
            color: $ldk-card-alert-color;
        }
    }

    .followup {
        .fa {
            color: #edad23;
        }
    }

    .completed {
        .fa {
            color: $ldk-card-information-color;
        }
    }

    .actionplanstatus {
        position: absolute;
        right: 10px;
        top: 15px;
    }
}
