.card-container{
	margin:0 auto;
	width: 100%;

	@media (min-width: $screen-md-min) {
		width: 660px;
	}
	@media (min-width: 1020px) {
		width: 990px;
	}
	@media (min-width: 1340px) {
		width: 1320px
	}
	@media (min-width: 1670px) {
		width: 1650px
	}
	@media (min-width: 2000px) {
		width: 1980px
	}
	@media (min-width: 2330px) {
		width: 2310px
	}
	@media (min-width: 2660px) {
		width: 2640px
	}

    .card {
        display: flex;
        flex-direction: column;        
    }

    .card-content {
        flex: 1;
        z-index: 1;
        overflow: hidden;
    }

    .background-logo-container {
        text-align: right;
        padding-right: 15px;
        img {
            width: 200px;
            height: auto;
        }
    }
}

@media (max-width: $screen-md-min) {
	.card-container{
		.card:not(.chart-card) {
			height:auto !important;
		}

        .card-content {
            flex-basis: auto;
        }

	}
}

@media (min-width: $screen-md-min) {
  .card-item {
    width: 330px;
    padding: 0 15px;
    float: left; 
  } 
}

@media (max-width: $screen-md-min) {
  .card-item {
    padding: 0 15px;
    width: 100%; 
    position: relative !important;
    top: 0 !important;
    //transform: none !important;
  } 
}

.card{
	@include card-shadows();

	border:0px none;
	border-radius:2px;
	margin-bottom:15px;
	border-bottom: 5px solid $ldk-cat-1-color;
	overflow:hidden;
	// height:240px;

	&.cat-2{
		border-bottom:5px solid $ldk-cat-2-color;
	}
	&.cat-3{
		border-bottom: 5px solid $ldk-cat-3-color;
	}
	&.cat-4{
		border-bottom: 5px solid $ldk-cat-4-color;
	}
	&.cat-5{
		border-bottom: 5px solid $ldk-cat-5-color;
	}
	&.cat-6{
		border-bottom: 5px solid $ldk-cat-6-color;
	}

	a{
	    color: $ldk-link-color;
		&:hover,
		&:focus{
			text-decoration: underline;
			
			.primary-img{
				opacity:0.8;
			}
		}

		.primary-img{
			width:760px;
		}
	}

	.nav-tabs{
		border-bottom:0px none;
		font-size: $ldk-navtab-font-size;
		margin-top:13px;
		text-transform: uppercase;

		a{
			border:0px none;
			border-radius:0px;
			padding:8px 8px 10px;
			color:$c-white;
			text-decoration: none;

			&.active,
			&.active:hover,
			&.active:focus{
				color:$c-black;
				font-family:$ldk-heavy-font;
			}
		}
		.nav-item+.nav-item{
			margin-left:11px;
		}
	}

	.carousel-navigation{
		padding:0 16px;
		text-align: right;
		width:100%;
	}

	.carousel-control{	
		position: relative;

		&.left,
		&.right{
			background:none;
			color:#777;
			color:rgba($c-black,0.54);
			font-size:26px;
			opacity:1;
			text-shadow:none;
		}

		&.left{
			padding-right:16px;
		}

		&.right{
			padding-left:16px;
		}

		&:hover,
		&:focus{
			text-decoration: none;
		}

		&:hover{
			color:$c-black;
		}

		&.disabled{
			cursor: not-allowed;
			opacity:0.4;

			&:hover{
				color:#777;
				color:rgba($c-black,0.54);
			}
		}
	}

	
}


.card-block,
.tab-content{
	color: $ldk-card-color;
	font-size:15px;
	padding:16px;

	p{
		// line-height:1.1;
		line-height:18px;
	}

    .card-block-alert{
        font-family: $ldk-heavy-font;
        font-size:30pt;
        color: $ldk-card-alert-color;
    }

    .card-block-warning{
        font-family: $ldk-heavy-font;
        font-size:30pt;
        color : $ldk-card-warning-color;
    }

    .card-block-information{
        font-family:$ldk-heavy-font;
        font-size:30pt;
        color: $ldk-card-information-color; 
    }

    .card-block-neutral{
        font-family:$ldk-heavy-font;
        font-size:30pt;
        color: $ldk-card-information-color; 
    }
}

.card-header{
	background:none;
	border-bottom:1px solid $c-border;
	font-size:24px;
	line-height:1.1;
	padding:16px 16px 4px 16px;

	&:first-child{
		border-radius:0px;
	}
}

.card-title{
	color: $ldk-card-title-color;
	padding-bottom:12px;
	padding-top: 8px;
	margin-bottom:0;
}

.card-subtitle{
	color: $ldk-card-subtitle-color;
	margin:-7px 0 0 0;
	padding-bottom:7px;
}

.card-secondary-links{
	padding:8px 16px 16px 16px;

	a{
		color:$c-black;
		font-family: $ldk-bold-font;
	    font-size: 20px;
	    line-height: 20px;

	    +a{
	    	margin-left:16px;
	    }
	}
}

.card-footer {
    z-index: 100;
	background:$c-white;
	bottom:0;
	border-top:1px solid $c-border;
	padding:8px;
	text-align:right;
	width:100%;
    height: $ldk-card-footer-height;
    max-height: $ldk-card-footer-height;

	a{
		color:$ldk-secondary-link-color;
		display: inline-block;
		line-height:16px;
		padding:8px;		
	}

	.lnk-favorite,
	.lnk-favorite:not([href]):not([tabindex]){
		cursor:pointer;
		float:left;
		padding-bottom:4px;
		// padding-top:4px;
		padding-top:8px;
		
		.fa{
			// font-size:24px;
			font-size:18px;
		}

		&.active{
			//color:$ldk-cat-3-color;
			color:$c-red;

			&:hover{
				color:$ldk-secondary-link-color;
			}
		}

		&:hover{
			//color:$ldk-cat-3-color;
			color:$c-red;
		}
	}

	.card-link{		
		font-family: $ldk-heavy-font;
		font-size:15px;
		text-transform: uppercase;

		+.card-link{
			margin-left:4px;
		}

		&.lnk-primary{
	        color: $ldk-link-color;
    		// color:#ed6823;
			// color:rgba(237,104,35,0.87);
		}

		&:hover{
			background:$ldk-link-hover-color;
			text-decoration: none;
		}
	}
}

.highlight {
    position: absolute;
    right: 0;
    top: 0;
}

.highlight-new {
    width: 55px;
    height: 55px;
    background-image: url(../images/highlight-new.png);
}

.highlight-beta {
    width: 55px;
    height: 55px;
    background-image: url(../images/highlight-beta.png);
}

.highlight-ad {
	width: 65px;
	height: 65px;
	background-image: url(../images/highlight-ad.png);
}

.card-list-title {
	border-radius: 0px;
	border: 0px none;
	margin-bottom: 0px;
	padding: 0;
	height:45px;

	a {
		color: $ldk-card-color;
		@include clearfix();
		display: block;

		&:hover {
			background: $ldk-link-hover-color;
		}

		&:hover,
		&:focus {
			text-decoration: none;

			img {
				opacity: 1;
			}
		}
	}
}


@media (min-width: $screen-md-min){

	.card{
		margin-bottom:30px;
		
		.primary-img,
		a .primary-img{
			// height:193px;
			// width:370px;	
			height:171px;
			width:300px;	
		}

		.nav-tabs{
			a{
				// padding:8px 19px 10px;
				padding:8px 8px 10px;
			}
		}
	}
}