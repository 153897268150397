.search-card-content {

    form {
        width: 100%;
        padding: 10px 16px;

        input {
            @include card-shadows();
            border: 0px none;
            height: 42px;
            padding: 10px 42px 10px 10px;
            width: 100%;

            &:focus {
                outline: none;
            }
        }

        button {
            margin-left: -42px;
            background: none;
            border: 0px none;
            color: $ldk-secondary-link-color;
            font-size: 18px;
            height: 42px;
            width: 42px;
            position: absolute;
            z-index:999;

            &:hover {
                background: $ldk-link-hover-color;
            }
        }


        ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: $ldk-secondary-link-color;
            font-style: italic;
        }

        ::-moz-placeholder { /* Firefox 19+ */
            color: $ldk-secondary-link-color;
            opacity: 1;
            font-style: italic;
        }

        :-ms-input-placeholder { /* IE 10+ */
            color: $ldk-secondary-link-color;
            font-style: italic;
        }

        :-moz-placeholder { /* Firefox 18- */
            color: $ldk-secondary-link-color;
            opacity: 1;
            font-style: italic;
        }
    }
}

@media (min-width: $screen-md-min) {
    .search-card-content {
        position: relative;

        form {
            position: absolute;
        }

        input {
        }

        button {
            z-index:auto;
            right: 16px;
            top: 10px;
        }
    }
}