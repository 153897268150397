.content-card {
    a:hover {
        background: $ldk-link-hover-color !important;
        text-decoration: none !important;
    }


    img {
        display: block;
        width: 760px;
        height: auto;
    }
}

@media (min-width: $screen-md-min){
	.content-card{
		// height: 330px;
		// height:313px;
		// height:292px;

		img{
			// height:193px;
			height:auto;
			width:auto;
		}
	}	
}