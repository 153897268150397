.chart-card {
    
    .card-content-wrapper {
        height: 100%;        
    }

    .chart-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        div[data-chart] {
            height: 100%;
            flex: 1;
        }
    }
}