.special-date-card{
	span{
		display:block;
		text-align:center;
		text-transform: uppercase;
	}

	.special-day{
		font-family:$ldk-light-font;
		font-size: 84px;
		line-height:70px;
	}

	.special-month{
		font-family:$ldk-heavy-font;
		font-size: 28px;
		line-height:30px;
	}
}

// @media (min-width: $screen-md-min){
// 	.special-date-card{
// 		height:270px;
// 	}
// }