.map-indicators{
	@include clearfix();
	padding:8px 16px;

	ul{
		margin:0;
		padding:0;
	}

	li{
		float:left;
		line-height:20px;
		list-style-type:none;
		margin:8px 0;
		min-height:30px;
		width:50%;

		&:nth-child(odd){
			clear:left;
			padding-right:8px;
		}
		&:nth-child(even){
			padding-left:8px;
		}
	}

	p{
		font-family:$ldk-bold-font;
		margin:0;
	}

	.single-indicator{
		display:inline-block;
		height:30px;
		position: absolute;
		width:30px;
		// border:2px solid $ldk-cat-3-color;
		border:1px solid $c-black;

		&.round0{
			// background:rgba($ldk-pagination-color,0.5);
			// border:2px solid $ldk-pagination-color;
			background-color: #F2F2F2;
		}

		&.round1{
			// background:rgba($ldk-button-color,0.5);
			// border:2px solid $ldk-button-color;
			background-color: #006B99;
		}

		&.round2{
			// background:rgba($ldk-cat-3-color,0.5);
			// border:2px solid $ldk-cat-3-color;
			background-color: #8aabb7;
		}
		&.round3{
			background-color: #88FFFF;
		}

	}

	.indicator-text{
		display: inline-block;
		font-family:$ldk-font;
		margin-top:5px;
		padding-left:38px;
		width:100%;
	}
}