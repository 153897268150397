.breaking-news-card{
	background:#ffeb00;
	
	.card-block{		
		padding:0;

		a{
			font-family:$ldk-heavy-font;
			font-size:25px;
			line-height:29px;
			text-align:center;
			text-transform: uppercase;		
			vertical-align:middle;	
			display:block;
			padding: 20px 16px;
			width:100%;

			&:hover,
			&:focus{
				text-decoration: none;
			}
		}

		&:hover{
			background: #dc0;
		}
	}

	.card-header,
	.card-footer{
		border-color:#ccbc00;
	}

	a,
	.card-footer a.lnk-primary{
	    color: $ldk-card-title-color;
	}

	.card-footer{
		background:#ffeb00;
		.card-link{
			&:hover{
				background: #dc0;
				// color:$ldk-link-hover-color;
			}
		}
	}

	
}

@media (min-width: $screen-md-min){
	.breaking-news-card{
		// height:255px;

		.card-block{
			a{
				// display:table-cell;
				height:134px;
				padding:16px;

				.outer-span{
					display: table-cell;
				    height: 102px;
				    vertical-align: middle;

				    span{
				    	display: block;
					    overflow: hidden;
					    max-height: 88px;
					    // width: 308px;
					    width: 268px;
				    }
				}
			}
		}
	}
}