.summax-card{
	.card-block{
	    color: $ldk-card-title-color;
		font-family: $ldk-font;	
		margin:10px 0;
		
		text-align: center;
		.fa{
			clear:both;
			color: $ldk-card-header-color-1;
			display:block;
			font-size: 50px;
		}
		&.summax-card-warning{
			.fa{
				color: $ldk-link-color;
			}
		}

		span{
			font-family: $ldk-bold-font;
		}
	}


}



@media (min-width: $screen-md-min){
	.summax-card{
		// height:255px;
	}
}