.my-overview {
    [data-toggle="collapse"] .fa:before {
        content: "\f139";
    }

    [data-toggle="collapse"].collapsed .fa:before {
        content: "\f13a";
    }

    .header {
        background-color: #f0f0e9;
        padding: 5px;
        /*font-size: 18px;*/
        margin-bottom: 5px;

        a {
            text-decoration: none;
            color: $ldk-navbar-link-color;
            font-family: $ldk-bold-font;
            margin-left: 5px;
            margin-right: 5px;
            white-space: nowrap;
        }
    }

    input[type="radio"].traffic-light {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        display: inline-block;
        width: 18px;
        height: 18px;
        padding: 0px;
        background-clip: content-box;
        border: 1px solid lightgray;
        border-radius: 50%;
        vertical-align: text-bottom;

        &:checked {
            border: 1px solid gray;
        }

        &:focus {
            outline: none;
        }

        &.green {
            background-color: rgba(0, 160, 0, 0.35);

            &:checked {
                background-color: #00A000;
            }
        }

        &.yellow {
            background-color: rgba(255, 215, 0, 0.35);

            &:checked {
                background-color: #FFD700;
            }
        }

        &.red {
            background-color: rgba(255, 0, 0, 0.35);

            &:checked {
                background-color: #FF0000;
            }
        }
    }
}
