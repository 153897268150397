$frontpage-button : #d05836 !default;
$frontpage-button-hover: #bd4b0f !default;

.frontpage{
	color:$c-black;
	font-family:$ldk-font;
	font-size:20px;
	line-height:24px;
	text-align: center;

	h1,
	h2,
	h3,
	h4{
		color:$c-black;
	}

	h1{
		font-family:$ldk-bold-font;
	}

	h2{
		font-family:$ldk-bold-font;
		font-size:26px;
		margin-bottom:32px;
		&.italic{
			font-family:$ldk-italic-font;
		}
	}

	p{
		margin-bottom:30px;
	}

	//HEADER
	.navbar{
		box-shadow:none;
		height:auto;
		margin-bottom:0px;
	}

	.navbar-brand{
		margin-right:0;
		max-width:100%;
		padding-left:0;
		padding-top:10px;
		img{
			margin-left:0;
		}
	}

	.nav-right{
		margin-bottom:10px;
		margin-top:10px;
		text-align: right;

		a{
			background:$frontpage-button;
			color:$c-white;
			display:inline-block;
			font-size:20px;
			line-height:20px;
			// height:40px;
			text-transform: uppercase;
			padding:10px 20px;

			&:hover{
				background:$frontpage-button-hover;
				text-decoration: none;
			}
		}
	}

	//CARDS
	.card-row{
		margin-top:20px;
		margin-bottom:0px;
	}

	.card{
		border-bottom:4px solid $ldk-pagination-color;
		border-radius:7px;
		box-shadow:none;
		margin-bottom:16px;

		.card-title{
			font-family:$ldk-bold-font;
		}

		img,
		.primary-image{
			height:auto;
			margin: 16px 0;
			width:100%;
		}

		.card-footer .card-link{
			font-size:17px;
		}
	}

	.lnk-cta{
		// background: #e95d0f;
		background:$frontpage-button;
		color:$c-white;
		display:inline-block;
		font-family:$ldk-bold-font;
		// font-size:34px;
		// line-height:36px;
		font-size:26px;
		line-height:26px;
		margin-bottom: 50px;
		// padding:10px 70px;
		padding:10px 40px;

		&:hover{
			background:$frontpage-button-hover;
			text-decoration: none;
		}
	}

	.examples{
		margin-bottom:16px;
		text-align:left;

		img{
			width:100%;
		}

		p{
			margin-bottom:10px;
		}
	}

	.modal-dialog{
		max-width:900px;
	}

	.border-elem{
		border:1px solid $c-black;
	}
}

.fp-row{
	padding:40px 0;

	a{
		color:$ldk-pagination-color;
	}
}

.row-green,
.row-petrol{
	background:#7c9877;
	color:$c-white;

	h1,
	h2,
	h3,
	h4{
		color:$c-white;
	}
}

.row-petrol{
	background: $ldk-pagination-color;

	a{
		color:$c-white;
	}
	
	.links-collection{
		font-size: 20px;

		a{
			text-decoration: underline;

			&:hover{
				text-decoration: none;
			}
		}
	}
}

// Responsive video in modal
.embed-container {
    height: 0;
    margin-bottom: 20px;
    max-width: 100%;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

@media (min-width: $screen-sm-min){
	.frontpage{
		.navbar-brand{
			padding-top: 20px;
		}

		.nav-right{
			margin-bottom:20px;
			margin-top:20px;

			a{
				font-size:26px;
				line-height:26px;
				padding:10px 40px;
			}
		}		
	}
}

@media (min-width: $screen-md-min){
	.frontpage{
		.card-row{
			margin-top:80px;
			margin-bottom:80px;
		}

		.examples{
			.examples-col-header{
				height:96px;
			}
		}
	}
}

@media (min-width: $screen-lg-min){
	.frontpage{
		.examples{
			.examples-col-header{
				height:72px;
			}
		}
	}
}
