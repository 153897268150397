@font-face {
    font-family: 'LFPressSansDemiBold';
    src: url('../fonts/LFPressSansDemiBold.woff2') format('woff2'),
        url('../fonts/LFPressSansDemiBold.woff') format('woff'),
        url('../fonts/LFPressSansDemiBold.ttf') format('truetype'),
        url('../fonts/LFPressSansDemiBold.svg#LFPressSansDemiBold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'LFPressSans';
    src: url('../fonts/LFPressSans.woff2') format('woff2'),
        url('../fonts/LFPressSans.woff') format('woff'),
        url('../fonts/LFPressSans.ttf') format('truetype'),
        url('../fonts/LFPressSans.svg#LFPressSans') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'LFPressSans-Poster';
    src: url('../fonts/LFPressSans-Poster.woff2') format('woff2'),
        url('../fonts/LFPressSans-Poster.woff') format('woff'),
        url('../fonts/LFPressSans-Poster.ttf') format('truetype'),
        url('../fonts/LFPressSans-Poster.svg#LFPressSans-Poster') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'LFPressSans-Italic';
    src: url('../fonts/LFPressSans-Italic.woff2') format('woff2'),
        url('../fonts/LFPressSans-Italic.woff') format('woff'),
        url('../fonts/LFPressSans-Italic.ttf') format('truetype'),
        url('../fonts/LFPressSans-Italic.svg#LFPressSans-Italic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'LFPressSansHeavy';
    src: url('../fonts/LFPressSansHeavy.woff2') format('woff2'),
        url('../fonts/LFPressSansHeavy.woff') format('woff'),
        url('../fonts/LFPressSansHeavy.ttf') format('truetype'),
        url('../fonts/LFPressSansHeavy.svg#LFPressSansHeavy') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'LFPressSansCaps';
    src: url('../fonts/LFPressSansCaps.woff2') format('woff2'),
        url('../fonts/LFPressSansCaps.woff') format('woff'),
        url('../fonts/LFPressSansCaps.ttf') format('truetype'),
        url('../fonts/LFPressSansCaps.svg#LFPressSansCaps') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'LFPressSans-BoldItalic';
    src: url('../fonts/LFPressSans-BoldItalic.woff2') format('woff2'),
        url('../fonts/LFPressSans-BoldItalic.woff') format('woff'),
        url('../fonts/LFPressSans-BoldItalic.ttf') format('truetype'),
        url('../fonts/LFPressSans-BoldItalic.svg#LFPressSans-BoldItalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'LFPressSans-Bold';
    src: url('../fonts/LFPressSans-Bold.woff2') format('woff2'),
        url('../fonts/LFPressSans-Bold.woff') format('woff'),
        url('../fonts/LFPressSans-Bold.ttf') format('truetype'),
        url('../fonts/LFPressSans-Bold.svg#LFPressSans-Bold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'LFPressSansLight';
    src: url('../fonts/LFPressSansLight.woff2') format('woff2'),
        url('../fonts/LFPressSansLight.woff') format('woff'),
        url('../fonts/LFPressSansLight.ttf') format('truetype'),
        url('../fonts/LFPressSansLight.svg#LFPressSansLight') format('svg');
    font-weight: normal;
    font-style: normal;
}

$ldk-navbar-icon-size       : 28px !default;
$ldk-navbar-link-size       : 17px !default;
$ldk-navtab-font-size       : 19px !default;

$ldk-optionalservice-title-font-size: 18px !default;

$base-font          : Arial, Verdana, sans-serif !default;
$ldk-bold-font      : 'LFPressSans-Bold', $base-font !default;
$ldk-font           : 'LFPressSans', $base-font !default;
$ldk-heavy-font     : 'LFPressSansHeavy', $base-font !default;
$ldk-light-font     : 'LFPressSansLight', $base-font !default;
$ldk-italic-font    : 'LFPressSans-Italic', $base-font !default;